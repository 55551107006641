import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import Loading from './components/utils/Loading';
import MainApp from './components/main_screens/main_app';
import Login from './components/main_screens/login';
import ImageViewer from './components/main_screens/image_viewer';
import RewardViewer from './components/main_screens/prize_viewer';
import AgeVerification from './components/main_screens/AgeVerification';
import {base} from './base';
import ReactGA from 'react-ga';

if(process.env.NODE_ENV === "production"){
  ReactGA.initialize(process.env.REACT_APP_GA_KEY);
}

function logPageView(path) {
    path = path || window.location.pathname;
    if(process.env.NODE_ENV === "production"){
        ReactGA.set({ page: path });
        ReactGA.pageview(path);
    }
}

function AuthenticatedRoute({component: Component, authenticated, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => {
                if(authenticated === true){
                    return (
                        <Component {...props} {...rest} />
                    )
                } else {
                    return (
                        <Redirect to={{pathname: '/login', state: {from: props.location}}} />
                    )
                }
            }} />
    )
}

class App extends Component {
    constructor(props) {
        super(props)
        this.setCurrentUser = this.setCurrentUser.bind(this);
        const userEmail = sessionStorage.getItem('userEmail') || false;
        this.state = {
            authenticated: !!userEmail,
            variables: {},
            loading: true,
            passedEmail: false
        }
    }

    setCurrentUser() {
        const userEmail = sessionStorage.getItem('userEmail') || false;
        this.setState({
            authenticated: !!userEmail
        })
    }

    componentDidMount() {
        let language = "english";
        let splitWindowLocation = window.location.pathname.split('/') || "";
        if(splitWindowLocation.length > 2){
          language = splitWindowLocation[2]
        } else if(splitWindowLocation.length > 1){
          language = splitWindowLocation[1]
        }
        this.loadLanguage(language)
        this.tenantVariablesRef = base.listenTo(`tenantVariables`, {
          context: this,
          state: 'tenantVariables',
          then(variables){
              document.title = variables.pageTitle || "Photo Scramble";
            this.setState({
                authenticated: false,
                variables: variables,
                loading: false,
                language: language
            })
          }
        });
      }

    setPassedEmail(isSet=true){
        this.setState({
            passedEmail: isSet
        })
    }

    checkForAgeGate(tenantVariables){
        const variable_age_boolean = tenantVariables.collectBirthday || false;
        if(!variable_age_boolean){
            return true
        }
        const formBirthday = tenantVariables.formBirthday || false;
        let variable_age = tenantVariables.allowedAge || 21;
        variable_age = parseInt(variable_age, 10);
        let user_age = sessionStorage.getItem('verifiedAge') || false;
        if(isNaN(parseInt(user_age))){
            user_age = false
        } else {
            user_age = parseInt(user_age, 10)
        }
        return (user_age && user_age >= variable_age) || formBirthday;
    }

    componentWillUnmount() {
        base.removeBinding(this.tenantVariablesRef);
    }

    async loadLanguage(language){
        let languagesConstants = null
        if(language){
            try {
                languagesConstants = await import("./constants/languages/"+language+".js");
            } catch(e) {
                language = process.env.REACT_APP_DEFAULT_LANGUAGE;
                languagesConstants = await import("./constants/languages/"+language+".js");
            }
        } else {
            language = process.env.REACT_APP_DEFAULT_LANGUAGE;
            languagesConstants = await import("./constants/languages/"+language+".js");
        }
        this.setState({
            lanaguageConstants: languagesConstants
        })
      }

    render() {
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        if (this.state.loading === true) {
            return (
                <Loading loading={this.state.loading}/>
            )
        }
        let redirectUrl = `/login`;
        if(this.state.language && !isMlbApp){
            redirectUrl += ("/" + this.state.language);
        }
        return (
          <div style={{ margin: "0 auto"}}>
            <BrowserRouter>
                <div className="main-content">
                    <div className="workspace">
                      <Switch>
                        <Route
                            strict
                            path="/login"
                            render={(props) => {
                                return <Login authenticated={this.state.authenticated} loadPage={() => logPageView()} setCurrentUser={this.setCurrentUser} variables={this.state.variables} setPassed={(setPassed)=>this.setPassedEmail(setPassed)} stringConstants={this.state.lanaguageConstants} checkForAgeGate={this.checkForAgeGate} {...props} />
                            }}
                        />

                        <Route
                            exact
                            path="/imageviewer"
                            render={(props) => {
                                return <ImageViewer loadPage={() => logPageView()} {...props} />
                            }}
                        />

                          <Route
                              exact
                              path="/prizeviewer"
                              render={(props) => {
                                  return <RewardViewer loadPage={() => logPageView()} {...props} />
                              }}
                          />

                          <Route
                              strict
                              path="/age_gate"
                              render={(props) => {
                                  return <AgeVerification loadPage={() => logPageView()} variables={this.state.variables} passedEmail={this.state.passedEmail} stringConstants={this.state.lanaguageConstants} checkForAgeGate={this.checkForAgeGate} {...props} />
                              }} />

                          <AuthenticatedRoute
                              exact
                              path="/"
                              authenticated={this.state.authenticated}
                              variables={this.state.variables}
                              passedEmail={this.state.passedEmail}
                              loadPage={() => logPageView()}
                              checkForAgeGate={this.checkForAgeGate}
                              stringConstants={this.state.lanaguageConstants}
                              component={MainApp} />

                          {/*<Route*/}
                          {/*    path="/"*/}
                          {/*    render={(props) => {*/}
                          {/*        return <MainApp variables={this.state.variables} passedEmail={this.state.passedEmail} stringConstants={this.state.lanaguageConstants} checkForAgeGate={this.checkForAgeGate} {...props} />*/}
                          {/*    }}*/}
                          {/*/>*/}

                          <Redirect to={redirectUrl}/>
                      </Switch>
                    </div>
                </div>
            </BrowserRouter>
          </div>
        )
    }
}

export default App;
