import React, { useState, useEffect } from 'react';
import '../../styles/css/SlidingPuzzle.css';
import { confirmAlert } from '../utils/react-confirm-alert';

const SlidingPuzzle = (props) => {
    const baseDistance = 34.5;
    const [state, setState] = useState({
        baseDistance: baseDistance,
        tileMap: {
            1: { tileNumber: 1, position: 1, top: 0, left: 0 },
            2: { tileNumber: 2, position: 2, top: 0, left: baseDistance },
            3: { tileNumber: 3, position: 3, top: 0, left: baseDistance * 2 },
            4: { tileNumber: 4, position: 4, top: baseDistance, left: 0 },
            5: { tileNumber: 5, position: 5, top: baseDistance, left: baseDistance },
            6: { tileNumber: 6, position: 6, top: baseDistance, left: baseDistance * 2 },
            7: { tileNumber: 7, position: 7, top: baseDistance * 2, left: 0 },
            8: { tileNumber: 8, position: 8, top: baseDistance * 2, left: baseDistance },
            empty: { position: 9, top: baseDistance * 2, left: baseDistance * 2 },
        },
        lastShuffled: null,
        shuffleTimeouts: [],
        numberOfMoves: 0
    });

    const setup = (tile) => {
        const tileId = tile.id;
        const tileMap = state.tileMap;
        const parentX = document.querySelector(".sliding-puzzle").clientHeight;
        const xMovement = parentX * (tileMap[tileId].left / 100);
        const yMovement = parentX * (tileMap[tileId].top / 100);
        tile.style.webkitTransform = "translateX(" + xMovement + "px) " + "translateY(" + yMovement + "px)";
        recolorTile(tile, tileId);
    };

    const tileClicked = (event) => {
        const successfulMove = moveTile(event.target);
        if (successfulMove !== false) {
            setState(prevState => ({
                ...prevState,
                numberOfMoves: prevState.numberOfMoves + 1
            }));
        }

        if (checkSolution()) {
            props.winningMessage(state.numberOfMoves + 1);
        }
    };

    const checkSolution = () => {
        const tileMap = state.tileMap;
        if (tileMap.empty.position !== 9) return false;

        for (const key in tileMap) {
            if ((key != 1) && (key !== "empty")) {
                if (tileMap[key].position < tileMap[key - 1].position) return false;
            }
        }
        return true;
    };

    // const shuffle = async () => {
    //     let shuffleDelay = 0;
    //     shuffleLoop();
    //
    //     let shuffleCounter = 0;
    //     while (shuffleCounter < 100) {
    //         shuffleDelay += Math.random() * 2;
    //         const tempShuffleArray = state.shuffleTimeouts;
    //         tempShuffleArray.push(setTimeout(shuffleLoop, shuffleDelay));
    //         await setState(prevState => ({
    //             ...prevState,
    //             shuffleTimeouts: tempShuffleArray
    //         }));
    //         shuffleCounter++;
    //     }
    // };

    const shuffle = () => {
        const numShuffles = 100;  // Set the number of shuffles
        for (let i = 0; i < numShuffles; i++) {
            const validMoves = getValidMoves();
            if (validMoves.length > 0) {
                const randomMove = validMoves[Math.floor(Math.random() * validMoves.length)];
                moveTile(document.getElementById(randomMove));
            }
        }
    };

    const getValidMoves = () => {
        const tileMap = state.tileMap;
        const emptyPosition = tileMap.empty.position;
        const movablePositions = movementMap(emptyPosition);
        const validMoves = [];
        for (let i = 1; i <= 8; i++) {
            if (movablePositions.includes(tileMap[i].position)) {
                validMoves.push(i);
            }
        }
        return validMoves;
    };

    const shuffleLoop = () => {
        if (props.easyMode) {
            // If easyMode is enabled, prevent the top three tiles from being shuffled
            const tileMap = state.tileMap;
            const topThreeTilesFixed = [1, 2, 3];
            let lastShuffled = state.lastShuffled;
            const emptyPosition = tileMap.empty.position;
            let shuffleTiles = movementMap(emptyPosition);
            shuffleTiles = shuffleTiles.filter(tilePosition => {
                return tileMap[tilePosition] && !topThreeTilesFixed.includes(tileMap[tilePosition].tileNumber);
            });
            const tilePosition = shuffleTiles[Math.floor(Math.floor(Math.random() * shuffleTiles.length))];
            let locatedTile;
            let locatedTileNumber;
            for (let i = 1; i <= 8; i++) {
                if (tileMap[i].position === tilePosition && !topThreeTilesFixed.includes(tileMap[i].tileNumber)) {
                    locatedTileNumber = tileMap[i].tileNumber;
                    locatedTile = document.querySelectorAll('.tile')[locatedTileNumber - 1];
                }
            }
            if (lastShuffled !== locatedTileNumber) {
                moveTile(locatedTile);
                setState(prevState => ({
                    ...prevState,
                    lastShuffled: locatedTileNumber
                }));
            } else {
                shuffleLoop();
            }
        } else {
            // Original shuffling logic for when easyMode is not enabled
            const tileMap = state.tileMap;
            let lastShuffled = state.lastShuffled;
            const emptyPosition = tileMap.empty.position;
            const shuffleTiles = movementMap(emptyPosition);
            const tilePosition = shuffleTiles[Math.floor(Math.floor(Math.random() * shuffleTiles.length))];
            let locatedTile;
            let locatedTileNumber;
            for (let i = 1; i <= 8; i++) {
                if (tileMap[i].position === tilePosition) {
                    locatedTileNumber = tileMap[i].tileNumber;
                    locatedTile = document.querySelectorAll('.tile')[locatedTileNumber - 1];
                }
            }
            if (lastShuffled !== locatedTileNumber) {
                moveTile(locatedTile);
                setState(prevState => ({
                    ...prevState,
                    lastShuffled: locatedTileNumber
                }));
            } else {
                shuffleLoop();
            }
        }
    };

    const solvePuzzle = () => {
        const tiles = document.querySelectorAll('.tile');
        const correctPosition = [
            { tileNumber: 1, position: 1, top: 0, left: 0 },
            { tileNumber: 2, position: 2, top: 0, left: baseDistance },
            { tileNumber: 3, position: 3, top: 0, left: baseDistance * 2 },
            { tileNumber: 4, position: 4, top: baseDistance, left: 0 },
            { tileNumber: 5, position: 5, top: baseDistance, left: baseDistance },
            { tileNumber: 6, position: 6, top: baseDistance, left: baseDistance * 2 },
            { tileNumber: 7, position: 7, top: baseDistance * 2, left: 0 },
            { tileNumber: 8, position: 8, top: baseDistance * 2, left: baseDistance },
            { position: 9, top: baseDistance * 2, left: baseDistance * 2 },
        ];
        const tileMap = { ...state.tileMap };
        for (let i = 0; i < tiles.length; i++) {
            const tileNumber = tiles[i].id;
            const parentX = document.querySelector(".sliding-puzzle").clientHeight;
            const emptyTop = correctPosition[i].top;
            const emptyLeft = correctPosition[i].left;
            const emptyPosition = correctPosition[i].position;
            const xMovement = parentX * (emptyLeft / 100);
            const yMovement = parentX * (emptyTop / 100);
            tiles[i].style.webkitTransform = "translateX(" + xMovement + "px) " + "translateY(" + yMovement + "px)";
            tileMap[tileNumber].top = emptyTop;
            tileMap[tileNumber].left = emptyLeft;
            tileMap[tileNumber].position = emptyPosition;
            recolorTile(tiles[i], tileNumber);
        }
        tileMap.empty.top = correctPosition[8].top;
        tileMap.empty.left = correctPosition[8].left;
        tileMap.empty.position = correctPosition[8].position;
        moveTile(tiles[7]);
        setState(prevState => ({ ...prevState, tileMap: tileMap }));
    };

    const movementMap = (position) => {
        if (position === 9) return [6, 8];
        if (position === 8) return [5, 7, 9];
        if (position === 7) return [4, 8];
        if (position === 6) return [3, 5, 9];
        if (position === 5) return [2, 4, 6, 8];
        if (position === 4) return [1, 5, 7];
        if (position === 3) return [2, 6];
        if (position === 2) return [1, 3, 5];
        if (position === 1) return [2, 4];
    };

    const tileMovable = (tileNumber) => {
        if (props.easyMode && tileNumber <= 3) return false;
        const tileMap = state.tileMap;
        const selectedTile = tileMap[tileNumber];
        const emptyTile = tileMap.empty;
        const movableTiles = movementMap(emptyTile.position);

        return movableTiles.includes(selectedTile.position);
    };

    const restartClicked = () => {
        confirmAlert({
            variables: props.variables,
            title: "Are You Sure?",
            message: "Restarting will delete all your progress",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        shuffle();
                        setState(prevState => ({
                            ...prevState,
                            numberOfMoves: 0
                        }));
                    }
                },
                {
                    label: 'No'
                }
            ]
        });
    };

    const recolorTile = (tile, tileId) => {
        if (tileId == state.tileMap[tileId].position) {
            tile.classList.remove("error");
        } else {
            tile.classList.add("error");
        }
        if (props.easyMode && tileId <= 3) {
            tile.style.border = `2px solid ${props.borderColor || 'red'}`;
        }
    };

    const moveTile = (tile) => {
        const tileNumber = tile.id;
        const tileMap = state.tileMap;
        const parentX = document.querySelector(".sliding-puzzle").clientHeight;
        if (!tileMovable(tileNumber)) {
            console.log("Tile " + tileNumber + " can't be moved.");
            return false;
        }

        const emptyTop = tileMap.empty.top;
        const emptyLeft = tileMap.empty.left;
        const emptyPosition = tileMap.empty.position;
        tileMap.empty.top = tileMap[tileNumber].top;
        tileMap.empty.left = tileMap[tileNumber].left;
        tileMap.empty.position = tileMap[tileNumber].position;

        const xMovement = parentX * (emptyLeft / 100);
        const yMovement = parentX * (emptyTop / 100);
        tile.style.webkitTransform = "translateX(" + xMovement + "px) " + "translateY(" + yMovement + "px)";

        tileMap[tileNumber].top = emptyTop;
        tileMap[tileNumber].left = emptyLeft;
        tileMap[tileNumber].position = emptyPosition;

        recolorTile(tile, tileNumber);
    };

    useEffect(() => {
        const tiles = document.querySelectorAll('.tile');
        let delay = -50;
        for (let i = 0; i < tiles.length; i++) {
            tiles[i].addEventListener('click', tileClicked, true);
            setTimeout(setup, delay, tiles[i]);
        }
        shuffle();
        return () => {
            for (let i = 0; i < tiles.length; i++) {
                tiles[i].removeEventListener('click', tileClicked, true);
            }
        };
    }, []);

    const tilesArray = props.items || [];
    const tenantVariables = props.variables || {};
    const developerMode = props.developerMode || false;
    const secondaryColor = tenantVariables.secondaryColor || "white";
    const primaryColor = tenantVariables.primaryColor || "black";
    const movesTaken = state.numberOfMoves || 0;
    const backgroundColor = tenantVariables.puzzleBackgroundColor || "#2C3E50";
    const logOutButtonColor = tenantVariables.logOutButtonColor || "white";

    return (
        <div>
            <figure className="sliding-puzzle-figure">
                <ul className="sliding-puzzle" style={{ backgroundColor: backgroundColor, borderColor: backgroundColor }}>
                    <li className={`tile${props.easyMode ? ' uninteractive' : ''}`} id={1} style={{ background: `url(${tilesArray[0].dataURI})`, backgroundSize: "cover" }} />
                    <li className={`tile${props.easyMode ? ' uninteractive' : ''}`} id={2} style={{ background: `url(${tilesArray[1].dataURI})`, backgroundSize: "cover" }} />
                    <li className={`tile${props.easyMode ? ' uninteractive' : ''}`} id={3} style={{ background: `url(${tilesArray[2].dataURI})`, backgroundSize: "cover" }} />
                    <li className="tile" id={4} style={{ background: `url(${tilesArray[3].dataURI})`, backgroundSize: "cover" }} />
                    <li className="tile" id={5} style={{ background: `url(${tilesArray[4].dataURI})`, backgroundSize: "cover" }} />
                    <li className="tile" id={6} style={{ background: `url(${tilesArray[5].dataURI})`, backgroundSize: "cover" }} />
                    <li className="tile" id={7} style={{ background: `url(${tilesArray[6].dataURI})`, backgroundSize: "cover" }} />
                    <li className="tile" id={8} style={{ background: `url(${tilesArray[7].dataURI})`, backgroundSize: "cover" }} />
                </ul>
            </figure>
            <div style={{ marginTop: 10 }}>
                <p style={{ color: logOutButtonColor || "white" }}><span style={{ fontSize: 30 }}>{movesTaken}</span></p>
            </div>
            {developerMode &&
                <div style={{ marginTop: 5 }}>
                    <button className="btn btn-next" onClick={() => solvePuzzle()}
                            style={{ backgroundColor: primaryColor || "black", color: secondaryColor || "white" }}>Solve Puzzle
                    </button>
                </div>
            }
            <div style={{ marginTop: 5 }}>
                <button className="btn btn-next" onClick={() => restartClicked()} style={{ backgroundColor: primaryColor || "black", color: secondaryColor || "white" }}>RESTART</button>
            </div>
            {/*<div style={{marginTop:10, float:"left"}}>*/}
            {/*    <button onClick={()=>props.showRealImage()} className="btn btn-logout" style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>Show Image</button>*/}
            {/*</div>*/}
        </div>
    );
}

export default SlidingPuzzle;
