import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import { unmountComponentAtNode } from "react-dom";
import {base} from '../../base';
import { confirmAlert } from '../utils/react-confirm-alert';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import Confetti from 'react-confetti';
import appBackgroundImage from '../../styles/images/background.png'
import sampleAppFrontImage from '../../styles/images/front_icon.png'
import sampleAppTopImage from '../../styles/images/top_icon.png'
import SlidingPuzzle from '../small_components/SlidingPuzzle';
import {validateEmail} from '../utils/HelpfulFunction';
import Loading from '../utils/Loading';
import { getDistance } from 'geolib';
import '../../styles/css/Home.css';
import '../../styles/css/ConfirmAlertCustom.css';
import '../../styles/css/main.css';
import fileDownload from 'js-file-download';
import { Textfit } from 'react-textfit';
const Axios = require('axios');

class Main_App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: sessionStorage.getItem('userEmail') || false,
            active: false,
            width: 0,
            height: 0,
            alreadyUpdate: "",
            currentGameId: null,
            currentGameTime: false,
            currentResponseArray: [],
            startConfetti: false,
            rotationStarted: false,
            optedIntoEmail: {},
            tierMessaging: [],
            currentTiles: [],
            modal: false,
            winnerModal: false,
            correctImage: null,
            userHighScore: null,
            locationChecking: true,
            downloadingImage: false,
            downloadedFile: null,
            loading: true,
            imageLoading: true
        };
        this.logout = this.logout.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.toggle = this.toggle.bind(this);
        this.winnerToggle = this.winnerToggle.bind(this);
        this.toggleRules = this.toggleRules.bind(this);
    }

    removeReactAlert(){
      document.body.classList.remove('react-confirm-alert-body-element');
      const target = document.getElementById('react-confirm-alert');
      if(target){
        unmountComponentAtNode(target);
        target.parentNode.removeChild(target)
      }
      const svg = document.getElementById('react-confirm-alert-firm-svg');
      if(svg){
        svg.parentNode.removeChild(svg);
        document.body.children[0].classList.remove('react-confirm-alert-blur')
      }
    }

    download(url) {
        const stringConstants = this.props.stringConstants;
        if(this.state.downloadedFile){
            fileDownload(this.state.downloadedFile, "reward.jpg")
            return
        }
        this.setState({
            downloadingImage: true
        })
        const variables = this.props.variables;
        const downloadImageRequest = {prizeImage: url, code: this.state.winningCode}
        const vm = this;
        let urlToPing = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/setUpRewardForDownload`;
        if(process.env.NODE_ENV === "development"){
            urlToPing = `http://localhost:5001/photoscrambledev/us-central1/api/setUpRewardForDownload`;
        }
        fetch(urlToPing, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(downloadImageRequest),
        }).then(res => {
            if(res){
                Promise.resolve(res.json()).then(function (value) {
                    if(!value || value.result === "no_prize_sent" || value.error){
                        confirmAlert({
                            variables: variables,
                            title: stringConstants.ERRORTEXT,
                            message: stringConstants.UNKNOWNERROROCCURED,
                            buttons: [
                                {
                                    label: stringConstants.OKTEXT
                                }
                            ]
                        })
                        vm.setState({
                            downloadingImage:false
                        })
                    } else {
                        Axios.get(value.newImage, {
                            responseType: 'blob',
                        }).then(res => {
                            fileDownload(res.data, "reward.jpg")
                            vm.setState({
                                downloadingImage:false,
                                downloadedFile: res.data
                            })
                        });
                    }
                }).catch(e =>{
                    confirmAlert({
                        variables: variables,
                        title: stringConstants.ERRORTEXT,
                        message: stringConstants.UNKNOWNERROROCCURED,
                        buttons: [
                            {
                                label: stringConstants.OKTEXT
                            }
                        ]
                    })
                    console.log(e)
                    vm.setState({
                        downloadingImage:false
                    })
                })
            } else {
                confirmAlert({
                    variables: variables,
                    title: stringConstants.ERRORTEXT,
                    message: stringConstants.UNKNOWNERROROCCURED,
                    buttons: [
                        {
                            label: stringConstants.OKTEXT
                        }
                    ]
                })
                vm.setState({
                    downloadingImage:false
                })
            }
        })
    }

    componentDidMount(){
      this.currentGameKeyRef = base.listenTo(`currentGame/id`, {
        context: this,
        then(key){
          if(typeof key === "string"){
            this.removeReactAlert();
            this.setState({
              currentGameId:key,
              startConfetti: false
            })
          }
        }
      });

        this.currentGameDeveloperModeRef = base.listenTo(`currentGame/developerMode`, {
            context: this,
            then(key){
                if(typeof key === "boolean") {
                    this.setState({
                        developerMode: key
                    })
                } else {
                    this.setState({
                        developerMode: false
                    })
                }
            }
        });

      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });
      const userEmail = this.state.userEmail;
      let base64EncodedEmail = btoa(userEmail);
      if(!validateEmail(userEmail)){
          base64EncodedEmail = userEmail;
      }

      this.tilesRef = base.bindToState('currentGame/images', {
        context: this,
        state: 'currentTiles',
        asArray: true
      })

      this.correctImageRef = base.bindToState('currentGame/correctImage', {
        context: this,
        state: 'correctImage'
      })

      this.gameActiveRef = base.bindToState('currentGame/active', {
        context: this,
        state: 'active',
      })

      this.easyModeRef = base.bindToState('currentGame/easyMode', {
          context: this,
          state: 'easyMode',
      })

      this.userHighScoreRef = base.bindToState(`userAnswers/${base64EncodedEmail}/numberOfMoves`, {
        context: this,
        state: 'userHighScore',
          then(){
              if(this.props.variables && this.props.variables.collectDistance && typeof this.state.userHighScore != "number" && this.props.variables.formattedAddress && this.props.variables.acceptableDistance){
                  this.checkUsersLocation();
              } else {
                  this.setState({
                      locationChecking: false,
                      loading:false
                  })
                  this.logUserActivity(this.state.currentGameId);
              }
          }
      })
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
      window.scrollTo(0,0)
        this.props.loadPage();
    }

    handleChange(event) {
      this.setState({[event.target.name]: event.target.value});
    }

    changeMilesToMeters(milesToConvert){
        return milesToConvert*1609.344;
    }

    getLocation(){
        const stringConstants = this.props.stringConstants;
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                reject(stringConstants.GEOLOCATIONNOTSUPPORTED);
            } else {
                const toCheckLatitude = this.props.variables.latitude || 51.525;
                const toCheckLongitude = this.props.variables.longitude || 7.4575;
                navigator.geolocation.getCurrentPosition(
                    function(position) {
                        resolve(getDistance({latitude: position.coords.latitude, longitude: position.coords.longitude}, {
                            latitude: toCheckLatitude,
                            longitude: toCheckLongitude,
                        }))
                    },
                    (err) => {
                        if(err.message === "User denied Geolocation"){
                            reject(stringConstants.NEEDBROWSERPERMISSION);
                        } else {
                            console.log(err.message);
                            reject(stringConstants.UNKNOWNERROROCCURED);
                        }
                    }
                );
            }
        })
    }

    getLocationPermission(){
        const stringConstants = this.props.stringConstants || {};
        const locationErrorTitle = stringConstants.LOCATIONERRORHEADER;
        this.getLocation().then(distance_meters => {
            sessionStorage.setItem('locationPermissions', "true");
            const allowed_distance = this.changeMilesToMeters(this.props.variables.acceptableDistance || 100); //In miles
            if(!this.props.variables.canPlayOutside && distance_meters <= allowed_distance){
                this.setState({
                    modal:false,
                    loading:false,
                    locationChecking: false
                });
                this.logUserActivity(this.state.currentGameId);
            } else if(this.props.variables.canPlayOutside && distance_meters > allowed_distance) {
                this.setState({
                    modal:false,
                    loading:false,
                    locationChecking: false
                });
                this.logUserActivity(this.state.currentGameId);
            } else {
                this.setState({
                    modal:false,
                    loading:false
                });
                let locationErrorMessage = "Too far from game area to participate!";
                if(this.props.variables.canPlayOutside){
                    locationErrorMessage = "Too close game area to participate!";
                }
                if(this.props.variables.notAcceptableLocationMessage){
                    locationErrorMessage = this.props.variables.notAcceptableLocationMessage;
                }
                let notAcceptableLocationHeader = locationErrorTitle;
                if(this.props.variables.notAcceptableLocationHeader){
                    notAcceptableLocationHeader = this.props.variables.notAcceptableLocationHeader;
                }
                confirmAlert({
                    title: notAcceptableLocationHeader,
                    variables: this.props.variables,
                    message: locationErrorMessage,
                    buttons: [
                        {
                            label: 'Retry',
                            onClick: () => {
                                this.checkUsersLocation()
                            }
                        }
                    ]
                });
            }
        }, error => {
            console.log(error);
            this.setState({
                modal:false,
                loading:false
            });
            sessionStorage.setItem('locationPermissions', "false");
            if(typeof error != "string"){
                error = error.message
            }
            confirmAlert({
                title: locationErrorTitle,
                variables: this.props.variables,
                message: error,
                buttons: [
                    {
                        label: 'Retry',
                        onClick: () => {
                            this.checkUsersLocation()
                        }
                    }
                ]
            });
        })
    }

    checkUsersLocation(){
        const stringConstants = this.props.stringConstants;
        const variables = this.props.variables;
        const locationPermissions = sessionStorage.getItem('locationPermissions');
        if(!this.props.variables.collectDistance) {
            this.logUserActivity(this.state.currentGameId);
            this.setState({locationChecking: false})
        } else if(locationPermissions === "false" || !locationPermissions){
            const locationPermissionsHeader = variables.locationPermissionsHeader || "Location Permissions Required";
            const locationPermissionsBody = variables.locationPermissionsBody || "We need your location in order to play! We use this information to make your experience better";
            confirmAlert({
                title: locationPermissionsHeader,
                variables: variables,
                message: locationPermissionsBody,
                buttons: [
                    {
                        label: stringConstants.YESCONFIRMBUTTONTEXT,
                        onClick: () => {
                            this.setState({
                                loading: true,
                            });
                            this.getLocationPermission()
                        }
                    }
                ],
            })
        } else {
            this.setState({
                loading: true,
            });
            this.getLocationPermission()
        }
    }

    componentWillUnmount() {
        base.removeBinding(this.currentGameKeyRef);
        base.removeBinding(this.currentGameDeveloperModeRef);
        base.removeBinding(this.tenantRulesRef);
        base.removeBinding(this.tilesRef);
        base.removeBinding(this.correctImageRef);
        base.removeBinding(this.gameActiveRef);
        base.removeBinding(this.userHighScoreRef);
        window.removeEventListener('resize', this.updateWindowDimensions);
        base.removeBinding(this.easyModeRef);
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
      });
    }

    winnerToggle() {
        this.setState({
            winnerModal: !this.state.winnerModal,
            startConfetti: false,
            downloadedFile: null
        });
    }

    toggleRules() {
        this.setState({
            modalRules: !this.state.modalRules,
        });
    }

    toggleSupport(){
        this.setState({
            modalSupport: !this.state.modalSupport,
        });
    }

    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    logout(){
        sessionStorage.removeItem('userEmail');
        sessionStorage.removeItem('verifiedAge');
        sessionStorage.removeItem('birthday');
        sessionStorage.removeItem('locationPermissions');
        this.setState({
            userEmail:false
        })
    }

    wrapText(context, text, x, y, maxWidth, lineHeight) {
        const words = text.split(' ');
        let line = '';
        let count = 1;

        for(let n = 0; n < words.length; n++) {
            const testLine = line + words[n] + ' ';
            const metrics = context.measureText(testLine);
            const testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                context.fillText(line, x, y);
                line = words[n] + ' ';
                y += lineHeight;
                count++;
            }
            else {
                line = testLine;
            }
        }
        context.fillText(line, x, y);
        return count*lineHeight;
    }

    fitTextOnCanvas(text,fontFace,yPosition,ctx, maxWidthAllowed){

        // start with a large font size
        let fontsize=45;

        // lower the font size until the text fits the canvas
        do{
            fontsize--;
            ctx.font=fontsize+"px "+fontFace;
        }while(ctx.measureText(text).width> maxWidthAllowed - 20)

        // draw the text
        ctx.fillText(text,maxWidthAllowed/2,yPosition);
    }

    fireWinningMessage(numberOfMoves){
      const userEmail = this.state.userEmail;
      const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
      let base64EncodedEmail = userEmail;
      if(validateEmail(userEmail)){
          base64EncodedEmail = btoa(userEmail)
      }
      const variables = this.props.variables;
      const stringConstants = this.props.stringConstants || {};
      this.setState({
          startConfetti: true
      })
      if(!this.state.userHighScore || typeof this.state.userHighScore === "object" || this.state.userHighScore === 0 || this.state.userHighScore > numberOfMoves) {
          const userResponse = {};
          userResponse['timeStamp'] = Date.now();
          userResponse['numberOfMoves'] = numberOfMoves;
          userResponse['uid'] = base64EncodedEmail;
          userResponse['isMlbTeam'] = isMlbApp;
          userResponse['mlbTeamId'] = process.env.REACT_APP_MLB_TEAM_ID;
          // this.setState({
          //     loading: true,
          // })
          const vm = this;
          let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/checkIfGetsPrize`;
          if(process.env.NODE_ENV === "development"){
              url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/checkIfGetsPrize`;
          }
          fetch(url, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(userResponse),
          }).then(res => {
              vm.setState({
                  loading: false
              })
              Promise.resolve(res.json()).then(function (value) {
                  if(value && value.rewardImage){
                      let prizeImage = value.rewardImage;
                      let code = null;
                      if(value && value.codes && value.codes.length > 0){
                          code = value.codes[0]
                      }
                      vm.setState({
                          winningPrizeImage: prizeImage,
                          winnerModal:true,
                          winningCode: code
                      })
                      let modalBackdrop = document.getElementsByClassName("modal-backdrop")[0];
                      modalBackdrop.style.display = "none";
                      let maxWidthAllowed = 300;
                      const bodyWidth = Math.max(document.body.offsetWidth, document.body.clientWidth, document.body.scrollWidth);
                      if(bodyWidth > 0 && bodyWidth < 300){
                          maxWidthAllowed = bodyWidth;
                      }
                      maxWidthAllowed = maxWidthAllowed - 10;
                      let c = document.createElement("canvas");
                      let cf = document.createElement("canvas");
                      cf.id = "finalCanvas";
                      let ctxf = cf.getContext('2d');
                      let ctx = c.getContext('2d');
                      let img1 = new Image();
                      img1.crossOrigin = "anonymous"
                      img1.onload= function() {
                          const proportionOfImage = img1.width/maxWidthAllowed;
                          let height = img1.height/proportionOfImage;
                          if(code){
                              vm.fitTextOnCanvas(code,"Oswald",(height + 25)* window.devicePixelRatio, ctx, maxWidthAllowed * window.devicePixelRatio);
                              cf.height = (height + 50) * window.devicePixelRatio;
                              cf.style.height = (height + 50).toString() +'px';
                          } else {
                              cf.height = (height) * window.devicePixelRatio;
                              cf.style.height = (height).toString() +'px';

                          }
                          cf.style.backgroundColor = "white";
                          ctx.fillStyle = "white";
                          //Add window.devicePixelRatio to make the image crisp
                          cf.width = maxWidthAllowed * window.devicePixelRatio;
                          // Style vs directly changing the canvas impacts pixels vs actual size: https://stackoverflow.com/questions/31910043/html5-canvas-drawimage-draws-image-blurry
                          cf.style.width = maxWidthAllowed.toString() +'px';
                          cf.style.outline = "2px black solid";
                          ctxf.textAlign = "center";
                          ctxf.textBaseline = "middle";
                          ctxf.fillStyle = "white";
                          ctxf.font = "25px Oswald";
                          if(code){
                              vm.fitTextOnCanvas(code,"Oswald",(height + 25)* window.devicePixelRatio, ctxf, maxWidthAllowed * window.devicePixelRatio);
                          }
                          ctx.drawImage(img1, 0, 0, maxWidthAllowed * window.devicePixelRatio, (img1.height/proportionOfImage)* window.devicePixelRatio);
                          ctxf.drawImage(img1, 0, 0, maxWidthAllowed * window.devicePixelRatio, (img1.height/proportionOfImage)* window.devicePixelRatio);
                          const finalCouponImage = new Image();
                          finalCouponImage.crossOrigin = "anonymous"
                          finalCouponImage.src = cf.toDataURL("image/jpeg");
                          //we want to keep the true size of the image so when its downloaded it is bigger, not clear the impact of this yet
                          finalCouponImage.width = img1.width;
                          finalCouponImage.height = img1.height;
                          //prevents the image from overflowing
                          finalCouponImage.style.maxWidth = "100%";
                          finalCouponImage.style.maxHeight = "100%";
                          const downloadModalIdElement = document.getElementById("downloadModalId");
                          //constrains the image to the visual space
                          downloadModalIdElement.style.width = maxWidthAllowed.toString() + "px";
                          if(code){
                              downloadModalIdElement.style.height = (height + 50).toString() + "px";
                          } else {
                              downloadModalIdElement.style.height = (height).toString() + "px";
                          }
                          downloadModalIdElement.appendChild(finalCouponImage);
                      };
                      img1.src = prizeImage;
                      ctx.font = "25px Oswald";
                  } else if (value.login_failure || !value.result) {
                      confirmAlert({
                          variables: variables,
                          title: stringConstants.ERRORTEXT,
                          message: stringConstants.UNKNOWNERROROCCURED,
                          buttons: [
                              {
                                  label: stringConstants.OKTEXT
                              }
                          ]
                      })
                  } else {
                      let messageHeader;
                      let messageBody;
                      if (value.result === "other_reward_sent") {
                          messageHeader = variables.winningOtherPrizeHeader;
                          messageBody = variables.winningOtherPrizeBody;
                      } else if (value.result === "no_prize_sent") {
                          messageHeader = variables.winningNoPrizeHeader;
                          messageBody = variables.winningNoPrizeBody;
                      } else if(value.result === "error_committed") {
                          messageHeader = stringConstants.ERRORTEXT || "There Was An Error";
                          messageBody = stringConstants.UNKNOWNERROROCCURED || "Something went wrong, check your internet connection and try again!";
                          confirmAlert({
                              variables: variables,
                              title: messageHeader,
                              message: messageBody,
                              buttons: [
                                  {
                                      label: stringConstants.RETRYTEXT,
                                      onClick: () => {
                                          vm.fireWinningMessage(numberOfMoves)
                                      }
                                  }
                              ]
                          })
                          return;
                      } else if (value.result === "main_reward_sent" || value.result === "prize_sent") {
                          messageHeader = variables.winningHeader;
                          messageBody = variables.winningBody;
                      }
                      if (!messageHeader) {
                          messageHeader = "You Solved The Puzzle!";
                      }
                      if (!messageBody) {
                          messageBody = "Nice job but can you do better? Try again and this time do it in less moves";
                      }
                      confirmAlert({
                          variables: variables,
                          title: messageHeader,
                          message: messageBody,
                          buttons: [
                              {
                                  label: stringConstants.DONETEXT,
                                  onClick: () => {
                                      if(process.env.REACT_APP_IS_MLB_TEAM === "true" && process.env.REACT_APP_MLB_TEAM_ID === "14"){
                                          let fetchUrl = `https://myportal.bluejays.com/PortalTest/provider/71?userid=${base64EncodedEmail}&win=true`;
                                          if(process.env.NODE_ENV === "production" && process.env.REACT_APP_FIREBASE_PROJECT_ID === "bluejaysphotoscramble"){
                                              fetchUrl = `https://myportal.bluejays.com/provider/71?userid=${base64EncodedEmail}&win=true`;
                                          }
                                          window.open(fetchUrl);
                                      }
                                      vm.setState({startConfetti: false})
                                  }
                              }
                          ]
                      })
                  }
              })
          }).catch(err =>{
              vm.setState({
                  loading: false
              })
              const messageHeader = variables.winningNoPrizeHeader || "You Solved The Puzzle!";
              const messageBody = variables.winningNoPrizeBody || "Nice job but can you do better? Try again and this time do it in less moves";
              confirmAlert({
                  variables: variables,
                  title: messageHeader,
                  message: messageBody,
                  buttons: [
                      {
                          label: stringConstants.OKTEXT,
                          onClick: () => vm.setState({startConfetti: false})
                      }
                  ]
              })
          })
      } else {
            const messageHeader = variables.winningNoPrizeHeader || "You Solved The Puzzle!";
            const messageBody = variables.winningNoPrizeBody || "Nice job but can you do better? Try again and this time do it in less moves";
            confirmAlert({
              variables: variables,
              title: messageHeader,
              message: messageBody,
              buttons: [
                {
                  label: stringConstants.OKTEXT,
                  onClick: () => {
                      if(process.env.REACT_APP_IS_MLB_TEAM === "true" && process.env.REACT_APP_MLB_TEAM_ID === "14"){
                          let fetchUrl = `https://myportal.bluejays.com/PortalTest/provider/71?userid=${base64EncodedEmail}&win=true`;
                          if(process.env.NODE_ENV === "production" && process.env.REACT_APP_FIREBASE_PROJECT_ID === "bluejaysphotoscramble"){
                              fetchUrl = `https://myportal.bluejays.com/provider/71?userid=${base64EncodedEmail}&win=true`;
                          }
                          window.open(fetchUrl);
                      }
                      this.setState({startConfetti: false})
                  }
                }
              ]
            })
        }
        if(this.state.currentGameId){
            this.logUserActivity(this.state.currentGameId);
        }
    }

    logUserActivity(currentGame){
      const vm = this;
      if(currentGame){
        const gameId = currentGame;
          let base64EncodedEmail = btoa(this.state.userEmail);
          if(!validateEmail(this.state.userEmail)){
              base64EncodedEmail = this.state.userEmail;
          }
        base.post('userGameHistory/'+base64EncodedEmail+`/${gameId}` , {
          data:gameId,
          then(err){
            if(!err){
              vm.setState({
                alreadyUpdate: gameId,
              })
              console.log("user game logged!")
            }
          }
        })
      }
    }

    renderHoldingScreen(){
        const tenantVariables = this.props.variables || {};
        const frontLogoImage = tenantVariables.frontLogoImage || sampleAppFrontImage;
        const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
        const playingTextHeader = tenantVariables.playingTextHeader || "No Game Up";
        const playingTextBody = tenantVariables.playingTextBody || "Come back later to play";
      return(
          <>
            <div className="hero-text-container">
              <img src={frontLogoImage} className="main-hero-image" alt="" onError={()=>this.setState({imageLoading: false})} onLoad={()=> this.setState({imageLoading: false})}/>
            </div>
            <p style={{color:logOutButtonColor}}>
                <span className="emphasizedText">
                    {playingTextHeader}
                </span>
                <br/>
                <span style={{color:logOutButtonColor}} dangerouslySetInnerHTML={{ __html:playingTextBody}}/>
            </p>
          </>
        )
    }

    renderPlayingScreen(){
        const tenantVariables = this.props.variables || {};
        const developerMode = this.state.developerMode || false;
        const topLeftImage = tenantVariables.topHeaderImage || sampleAppTopImage;
        return(
            <>
              <div className="creator-awards-logo-playscreen user">
                  <img id="header-image" src={topLeftImage} alt="" style={{margin:0}} onError={()=>this.setState({imageLoading: false})} onLoad={()=> this.setState({imageLoading: false})}/>
              </div>
              <div className="spacer-md"/>
              {this.state.currentTiles && this.state.currentTiles.length > 0 &&
                <>
                  <SlidingPuzzle easyMode={this.state.easyMode} borderColor={tenantVariables.easyModeBorderColor} developerMode={developerMode} winningMessage={(numberOfMoves)=>this.fireWinningMessage(numberOfMoves)} showRealImage={()=>this.toggle()} items={this.state.currentTiles} variables={tenantVariables} />
                  <div className="spacer-md"/>
                </>
              }
              <div style={{height:"100px", width:"1px"}}/>
            </>
        )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevState.userHighScore && !this.state.userHighScore){
        this.removeReactAlert();
        this.logUserActivity(this.state.currentGameId);
        this.setState({
          startConfetti:false
        })
      }
    }

    render() {
        const tenantVariables = this.props.variables || {};
        const tenantRules = this.state.tenantRules || {};
        const stringConstants = this.props.stringConstants || {};
        const backgroundImage = tenantVariables.backgroundImage || appBackgroundImage;
        const verifiedAge = this.props.checkForAgeGate(tenantVariables);
        const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
        const primaryColor = tenantVariables.primaryColor || "black";
        const secondaryColor = tenantVariables.secondaryColor || "white";
        const rulesInAppButtonText = tenantRules.rulesInAppButtonText || "Rules & Regs";
        const mlbPrivacyPolicyLink = tenantRules.mlbPrivacyPolicyLink || "https://www.mlb.com/app/ballpark/official-information/privacy-policy";
        let rulesShowInAppPopUpHeader = tenantRules.rulesShowInAppPopUpHeader;
        let rulesShowInAppPopUpText = tenantRules.rulesShowInAppPopUpText;
        let rulesPopUpText = tenantRules.rulesPopUpText;
        let rulesPopUpHeader = tenantRules.rulesPopUpHeader;
        const supportHeader = tenantRules.supportHeader || "NEED SUPPORT?";
        const supportText = tenantRules.supportText || "Email customerservice@website.mlb.com with your issue and we'll be in touch!";
        const link = tenantRules.rulesAndRegsLink;
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        const rulesShowInApp = tenantRules.rulesShowInApp || (isMlbApp && !tenantVariables.noMandatoryTermsAndConditions) || false;
        if(rulesShowInApp) {
            if(!rulesShowInAppPopUpText && rulesPopUpText){
                rulesShowInAppPopUpText = rulesPopUpText;
            }
            if(!rulesShowInAppPopUpHeader && rulesPopUpHeader){
                rulesShowInAppPopUpHeader = rulesPopUpHeader;
            }
        }
        if(!verifiedAge){
            let urlToGo = "/age_gate";
            let langauge = "/";
            let splitWindowLocation = window.location.pathname.split('/') || "";
            if(splitWindowLocation.length > 1){
                langauge += splitWindowLocation[1];
                urlToGo += langauge
            }
            return (
                <Redirect to={urlToGo} />
            )
        }
        if(!this.state.userEmail){
            let redirectString = "/login";
            let langague = "";
            let splitWindowLocation = window.location.pathname.split('/') || "";
            if(window.location.pathname.split('/').length > 1){
                langague = "/" + splitWindowLocation[1]
                redirectString += langague
            }
            return (
                <Redirect to={redirectString} />
            )
        } else if(!tenantVariables.doNotCollectEmail && !validateEmail(this.state.userEmail) && !isMlbApp){
          this.logout();
        } else if(tenantVariables.doNotCollectEmail && validateEmail(this.state.userEmail) && !isMlbApp){
          this.logout();
        }
        if (this.state.loading === true) {
            return (
                <Loading loading={this.state.loading} backgroundImage={backgroundImage}/>
            )
        }
        let renderHoldingScreen = true;
        let renderMainScreen = this.renderHoldingScreen()
        if(this.state.active === true && !this.state.locationChecking){
            renderMainScreen = this.renderPlayingScreen();
            renderHoldingScreen = false;
        }
        let maxWidthAllowed = 300;
        const bodyWidth = Math.max(document.body.offsetWidth, document.body.clientWidth, document.body.scrollWidth);
        if(bodyWidth > 0 && bodyWidth < 300){
            maxWidthAllowed = bodyWidth;
        }
      return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + backgroundImage + ")", scrollbarWidth:"none"}}>
              {this.state.startConfetti &&
                <Confetti numberOfPieces={400} width={this.state.width} height={this.state.height} style={{zIndex: 1}}/>
              }
            <div className="flex-content-container-home">
                <div className="intro-container-home" style={{margin: "auto"}}>
                    <div className="grid-wrapper">
                        {(!this.state.imageLoading || (!tenantVariables.frontLogoImage && renderHoldingScreen)) &&
                            <div className="flex-header-home" style={{marginTop:10, justifyContent: "space-between", maxWidth: "100%", minWidth: 280, width:"100vw"}}>
                                {isMlbApp ?
                                    <div style={{width: "25%", height: 38, color: logOutButtonColor, borderColor: logOutButtonColor, border: "solid 1px", borderRadius: 5, flexGrow:1, maxWidth: 100}}>
                                        <button className="btn" style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => { this.toggleSupport() }}>
                                            <Textfit mode="single" max={16}>
                                                Support
                                            </Textfit>
                                        </button>
                                    </div>
                                    :
                                    <div style={{display: this.state.active === true && !this.state.locationChecking ?"": "none", width: "25%", height: 38, color: logOutButtonColor, borderColor: logOutButtonColor, border: "solid 1px", borderRadius: 5, flexGrow:1, maxWidth: 100}}>
                                        <button onClick={()=>this.toggle()} className="btn" style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}}>
                                            <Textfit mode="single" max={16}>
                                                {stringConstants.SHOWIMAGETEXT}
                                            </Textfit>
                                        </button>
                                    </div>
                                }
                                {isMlbApp &&
                                    <div style={{ width: "25%", color: logOutButtonColor, borderColor: logOutButtonColor, height: 38, border: "solid 1px", borderRadius: 5, flexGrow:1, maxWidth: 100}}>
                                        <button className="btn" style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => {window.open("https://www.mlb.com/app/ballpark/official-information/terms-of-use", "_blank")}}>
                                            <Textfit mode="single" max={16}>
                                                MLB TOU
                                            </Textfit>
                                        </button>
                                    </div>
                                }
                                {isMlbApp &&
                                    <div style={{ width: "25%", height: 38, color: logOutButtonColor, borderColor: logOutButtonColor, border: "solid 1px", borderRadius: 5, flexGrow:1, maxWidth: 100}}>
                                        <button className="btn" style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => { window.open(mlbPrivacyPolicyLink, "_blank") }}>
                                            <Textfit mode="single" max={16}>
                                                MLB Privacy Policy
                                            </Textfit>
                                        </button>
                                    </div>
                                }
                                {rulesShowInApp &&
                                    <div style={{width: "25%", height: 38, color: logOutButtonColor, borderColor: logOutButtonColor, border: "solid 1px", borderRadius: 5, maxWidth: 100}}>
                                        <button className="btn" style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => { rulesShowInAppPopUpText?this.toggleRules():window.open(link, '_blank') }}>
                                            <Textfit mode="single" max={16}>
                                                {rulesInAppButtonText}
                                            </Textfit>
                                        </button>
                                    </div>
                                }
                                <div style={{display: (isMlbApp || this.props.passedEmail) && "none", width: "25%", height: 38, color: logOutButtonColor, borderColor: logOutButtonColor, border: "solid 1px", borderRadius: 5, marginLeft: !rulesShowInApp && !isMlbApp &&"auto", maxWidth: 80}}>
                                    <button className="btn" onClick={() => { this.logout() }} style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}}>
                                        <Textfit mode="single" max={16}>
                                            LOG OUT
                                        </Textfit>
                                    </button>
                                </div>
                            </div>
                        }
                        {/*<div className="flex-header-home" style={{marginTop:10}}>*/}
                        {/*    <div style={{display: this.state.active === true && !this.state.locationChecking ? isMlbApp?"none":"": "none"}}>*/}
                        {/*        <button onClick={()=>this.toggle()} className="btn btn-logout" style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>{stringConstants.SHOWIMAGETEXT}</button>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div style={{display: this.state.active === true && !this.state.locationChecking && isMlbApp ? "": "none", marginTop: 10}}>
                            <button onClick={()=>this.toggle()} className="btn btn-logout" style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>{stringConstants.SHOWIMAGETEXT}</button>
                        </div>

                        {renderMainScreen}
                        {/*<div style={{display: isMlbApp? "block":"none", textAlign: "center"}}>*/}
                        {/*    <button className="btn btn-logout" onClick={() => { this.toggleSupport() }} style={{color: logOutButtonColor, borderColor: logOutButtonColor}}>Support</button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} id="myModal">
              <img style={{padding:10, margin:"auto"}} width={300} height={300} src={this.state.correctImage} alt=""/>
            </Modal>
            <Modal isOpen={this.state.winnerModal} id="myOtherModal">
              <center style={{maxWidth:maxWidthAllowed, marginTop:10, marginBottom: 10}}>
                  <span className="unselectable">{stringConstants.HOLDYOURFINGERDOWNTODOWNLOADTEXT}<br/>👇👇👇👇👇👇</span>
                  <div className="unselectable" style={{height:10, width: "100%"}}/>
                  <center id="downloadModalId"/>
              </center>
            </Modal>
            <Modal isOpen={this.state.modalRules} style={{width: '90%'}} id="rulesModal">
              <ModalHeader style={{color: secondaryColor, padding:10}}>
                <span style={{fontSize:25}}>
                    {rulesShowInAppPopUpHeader}
                </span>
              </ModalHeader>
              <ModalBody>
                  <center className="container-out">
                      <div className="question-box question-form">
                          <p style={{width:"100%",height:200, overflow:"scroll",padding:10, borderBottom:"1px solid black", borderTop: "1px solid black", fontWeight:100, fontSize:14}} dangerouslySetInnerHTML={{ __html:rulesShowInAppPopUpText}}/>
                          <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>{stringConstants.DISMISSTEXT}</button>
                      </div>
                  </center>
              </ModalBody>
            </Modal>
            <Modal isOpen={this.state.modalSupport} style={{width: '90%'}} id="rulesModal">
                <ModalHeader style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {supportHeader}
                    </span>
                </ModalHeader>
                <ModalBody>
                  <center className="container-out">
                      <div className="question-box question-form">
                          <p style={{width:"100%",overflow:"scroll",fontWeight:100, fontSize:14, marginBottom: 0}} dangerouslySetInnerHTML={{ __html:supportText}}/>
                          <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleSupport(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', fontSize:'1.3 rem'}}>Dismiss</button>
                      </div>
                  </center>
                </ModalBody>
            </Modal>
          </div>
      )
    }
}

export default Main_App;
